import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import "./style.css";
import { useMobileSize } from "../../hooks/useMobileSize";
import { useEffect } from "react";

export const PageNotFound = () => {
  const isMobile = useMobileSize();

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMobile]);

  return (
    <>
      <div className="not_found_page_wrapper">
        <div className="first_gradient"></div>
        <div className="second_gradient"></div>
        <div className="main_content">
          <span className="page_title">{isMobile ? "404" : "ERROR 404"}</span>
          <span className="page_content">
            The page you are looking for doesn’t exist or has been moved
          </span>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button
              text="BACK TO HOMEPAGE"
              className="button download_btns back_home_btn download_btns_mobile"
              textStyle="button_text back_home_btn_text"
            />
          </Link>
        </div>
      </div>
    </>
  );
};
