import React from "react";
import "./style.css";
import { Button } from "../Button";
import { useScrollTo } from "../../hooks/useScrollTo";

export const Navbar = ({ iconOnOnClick }) => {
  const visible = useScrollTo();

  return (
    <div className={`${!visible && "hidden_navbar"} navbar_wrapper `}>
      <img
        src={
          "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Capschat-images/capschat_logo.png"
        }
        alt="logo"
        width="100%"
        height="100%"
        className="logo"
        onClick={() => iconOnOnClick()}
      />
      <Button
        text="TRY IT"
        className="button download_btns"
        textStyle="button_text"
        // buttonLink={"https://chat.solvee.am/download"}
        buttonLink={"https://ho1bdk3rwut.typeform.com/to/UzYv9eum"}
      />
    </div>
  );
};
