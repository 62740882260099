export const cardsData = [
  {
    title: "One click \n accessibility ",
    info: "One button away, just press the CapsLock key on your keyboard and talk with the most powerful language models.",
  },
  {
    title: "User-friendly \n interface",
    info: "CapsChat has a clean, intuitive, and easy-to-use interface, making AI interaction as simple as possible.",
  },
  {
    title: "Privacy-Focused \n Design",
    info: "CapsChat prioritizes user privacy with strong encryption, keeping data secure and confidential.",
  },
];
