import "./style.css"

export const Loading = () => {
  return (
    <div className="loading_page">
    <div className="spinner">
      <div className="spinner__circle">
        <div className="spinner__circle-gradient"></div>

        <div className="spinner__circle-inner"></div>
      </div>
    </div>
    </div>
  );
};
