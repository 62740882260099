import "./App.css";
import Lenis from "@studio-freight/lenis";
import { Navigate, Route,  BrowserRouter as Router, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { PageNotFound } from "./pages/PageNotFound";

function App() {
  const lenis = new Lenis({
    duration: 3.05,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    direction: "vertical",
    gestureDirection: "vertical",
    smooth: true,
    mouseMultiplier: 1,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
  });
  const raf = (time) => {
    lenis.raf(time);
    requestAnimationFrame(raf);
  };
  requestAnimationFrame(raf);

  const scrollToTop = () => {
    lenis.duration = 0;
    window.scrollTo(0, 0);

    setTimeout(function () {
      lenis.duration = 3.05;
    }, 10000);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home scrollToTop={scrollToTop} />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
