import { useState } from 'react';

const useFontLoader = () => {
  const [fontLoaded, setFontLoaded] = useState(false);

  if ('fonts' in document) {
  Promise.all([
    document.fonts.load('1em SF Pro Bold'),
    document.fonts.load('1em SF Pro'),
  ]).then(_ => {
    setFontLoaded(true)
  })
}

  return fontLoaded;
};

export default useFontLoader;