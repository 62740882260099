import { Button } from "../../components/Button";
import "./style.css";
import { InfiniteSquareText } from "../../components/InfiniteSquareText";
import { useRef } from "react";
import useSectionVisibility from "../../hooks/useSectionVisibility";

export const Download = () => {
  const sectionRef = useRef(null);
  const isSectionVisible = useSectionVisibility(sectionRef);

  return (
    <div className="download_page_wrapper" ref={sectionRef}>
      <div className="download__info__wrapper">
        <h1 className={`font__text gradient__download__text ${isSectionVisible && "gradient__download__text__animation"}`}>
          <span style={{ animationDelay: "0.3s" }}>Simplicity Meets AI:</span>
          <span style={{ animationDelay: "0.5s" }}>ChatGPT at your</span>
          <span style={{ animationDelay: "0.7s" }}>fingertips.</span>
        </h1>
      </div>
      <Button
        text="TRY IT"
        textStyle="button_text"
        className="button download_btns download_btns_mobile"
        // buttonLink="https://chat.solvee.am/download"
        buttonLink={"https://ho1bdk3rwut.typeform.com/to/UzYv9eum"}
      />
      <InfiniteSquareText />
    </div>
  );
};
