import DISCORD_ICON from "../assets/companies_icons/discord_icon.svg";
import TWITTER_LOGO from "../assets/companies_icons/twitter_icon.svg";
import SLACK_ICON from "../assets/companies_icons/slack_icon.svg";

export const companiesData = [
  {
    src: TWITTER_LOGO,
    alt: "twitter_logo",
    link: "https://twitter.com/CapsChatAi",
  },
  {
    src: SLACK_ICON,
    alt: "slack_logo",
    link: "https://slack-s6j7678.slack.com",
  },
  {
    src: DISCORD_ICON,
    alt: "discord_logo",
    link: "https://discord.gg/9G3GVjXx",
  },
];
