import "./style.css";
import { cardsData } from "../../data/cardsData";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

gsap.registerPlugin(ScrollTrigger);
export const Features = () => {
  const barRef = useRef([]);
  const sectionRef = useRef(null);
  const featureCardsRef = useRef([]);
  const featuresWrapperRef = useRef([]);

  useEffect(() => {
    const animationTargets = [...featureCardsRef.current, ...barRef.current];

    animationTargets.forEach((target, i) => {
      const isBar = barRef.current.includes(target);

      gsap.to(target, {
        autoAlpha: 1,
        ease: "power1.ease",
        repeat: isBar ? -1 : 0,
        scrollTrigger: {
          trigger: target,
          pin: true,
          scrub: true,
          start:  isBar ? "top +=37%" : "top top",
          end: isBar && `+=${featuresWrapperRef.current.offsetHeight - 480}`,
        },
      });
    });
  }, []);

  return (
    <>
      <div className="gap"></div>
      <div className="gap bottom"></div>
      <div className="features_page_wrapper" ref={sectionRef}>
        <div className="features_wrapper" ref={featuresWrapperRef}>
          {cardsData.map((feature, i) => (
            <div key={feature + i}>
              <div className="bar" ref={(el) => (barRef.current[i] = el)}></div>
              <div
                key={`feature_${i}`}
                className="feature_card"
                ref={(el) => (featureCardsRef.current[i] = el)}
              >
                <span className="feature_title feature_card_active">
                  {feature.title}
                </span>
                <span className="feature_info">{feature.info}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
