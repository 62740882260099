import { Loading } from "../components/Loading";
import { Navbar } from "../components/Navbar";
import { ProgressBar } from "../components/ProgressBar";
import useFontLoader from "../hooks/useFontLoader";
import { About } from "../sections/About";
import { Download } from "../sections/Download";
import { Features } from "../sections/Features";
import { Footer } from "../sections/Footer";
import { IntegrationAnimation } from "../sections/IntegrationAnimation";

export const Home = ({scrollToTop}) => {
  const isFontLoad = useFontLoader();
  return (
    <>
      {!isFontLoad ? (
        <Loading />
      ) : (
        <>
          <ProgressBar />
          <Navbar iconOnOnClick={() => scrollToTop()} />
          <About />
          <Features />
          <IntegrationAnimation />
          <Download />
          <Footer />
        </>
      )}
    </>
  );
};
