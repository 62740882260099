import { useEffect, useState } from "react";

export const useMobileSize = () => {
  const [isMobileView, setIsMobile] = useState(
    () => window.matchMedia("(max-width: 800px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 800px)");

    const updateMobileStatus = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", updateMobileStatus);

    return () => {
      mediaQuery.removeEventListener("change", updateMobileStatus);
    };
  }, []);

  return isMobileView;
};
