import "./style.css";
import { useEffect, useRef } from "react";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const ProgressBar = () => {
  const progressRef = useRef(null);

  useEffect(() => {
    const progress = gsap.timeline({
      scrollTrigger: {
        scrub: true,
        trigger: ".App",
        start: 0,
        end: "max",
      },
    });

    progress.to(progressRef.current, {
      value: 100,
      ease: "none",
    });
  }, [progressRef]);

  return <progress max="100" value="0" ref={progressRef}></progress>;
};
