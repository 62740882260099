import "./style.css";
import { SectionMainInfo } from "../../components/SectionMainInfo";
import { InfiniteVideo } from "../../components/InfiniteVideo";
import { useEffect } from "react";
import gsap from "gsap";

export const About = () => {
  const aboutText = [
    "Digital personal",
    "assistant",
    "Powered with GPT",
    "technologies",
  ];

  const videoUrlWEBM =
    "https://solvee-web-site.s3.eu-west-3.amazonaws.com/videos/Solo+Chat+28.07.23+with+light_VP9.webm";
  const videoUrlMP4 =
    "https://solvee-web-site.s3.eu-west-3.amazonaws.com/videos/Solo+chat+with+bg.mp4";
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const opacity = Math.max(0, 1 - scrollTop / 500);
      gsap.to(".hero_page_wrapper", {
        opacity: opacity,
        duration: 0.5,
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="hero_page_wrapper">
      <div className="hero_page_content_wrapper">
        <div className="video_wrapper">
          <InfiniteVideo
            videoUrlWEBM={videoUrlWEBM}
            videoUrlMP4={videoUrlMP4}
          />
        </div>
        <div className="hero_page_titles_wrapper">
          <div className="top_title">
            {aboutText.slice(0, 2).map((text, ind) => (
              <SectionMainInfo
                key={text + ind}
                text={text}
                className={`gradient__text first_line_delay ${
                  ind === 1 ? "italic__text" : ""
                }`}
              />
            ))}
          </div>
          <div className="bottom_title">
            {aboutText.slice(2, 4).map((text, ind) => (
              <SectionMainInfo
                key={text + ind}
                text={text}
                className="gradient__text italic__text second_line_delay"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
