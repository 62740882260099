import { useState } from "react";
import "./style.css";

export const Button = ({
  text,
  className,
  imgSrc,
  hoverImgSrc,
  buttonLink,
  textStyle,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <a
      href={buttonLink}
      target="_blank"
      rel="noreferrer"
      className="button__link"
    >
      <button
        tabIndex="-1"
        className={`${className} fonts_style`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {imgSrc && <img src={hover ? hoverImgSrc : imgSrc} alt="icon" />}
        {className === "stop_generating_button" && (
          <div className="stop_generating_icon"></div>
        )}
        <span className={textStyle}>{text}</span>
      </button>
    </a>
  );
};
