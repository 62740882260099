import { useEffect, useRef, useState } from "react";
import "./style.css";
import { useScrollPosition } from "../../hooks/useScrollPosition";

export const InfiniteVideo = ({ videoUrlWEBM, videoUrlMP4 }) => {
  const videoRef = useRef(null);
  const scrollPosition = useScrollPosition();
  const [isVisible, setIsVisible] = useState(true);

  const handleVideoEnd = () => {
    videoRef.current.play();
  };

  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener("ended", handleVideoEnd);

    return () => {
      video.removeEventListener("ended", handleVideoEnd);
    };
  }, []);

  useEffect(() => {
    setIsVisible(scrollPosition < 1500);
  }, [scrollPosition]);

  return (
    <video
      className="about_video"
      style={{
        visibility: isVisible ? "visible" : "hidden",
      }}
      ref={videoRef}
      controls={false}
      preload="auto"
      autoPlay
      muted
      playsInline
    >
      <source src={videoUrlWEBM} type="video/webm"></source>
      <source src={videoUrlMP4} type="video/mp4"></source>
    </video>
  );
};
