import "./style.css";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import { Button } from "../../components/Button";

export const IntegrationAnimation = () => {
  const questionTxtRef = useRef(null);
  const answerTxtRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const splittedQuestionTxt = new SplitType(questionTxtRef.current, {
      type: "chars",
    });
    const splittedAnswerTxt = new SplitType(answerTxtRef.current, {
      type: "chars",
    });

    const parentContainer = questionTxtRef.current;
    if (parentContainer) {
      const children = parentContainer.children;
      for (let i = 0; i < children.length; i++) {
        const lineDiv = children[i];
        const carrotDiv = document.createElement("div");
        carrotDiv.className = "word carrot";
        carrotDiv.textContent = "|";
        lineDiv.appendChild(carrotDiv);
      }
    }

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".integration_animation_page_wrapper",
        start: "top top",
        pin: true,
        scrub: true,
        // onUpdate: (self) => {
        //   const progress = self.progress;
        //   progress >= 0.6096491228070176 && progress <= 0.7054146341463414
        //     ? gsap.set(messageWrapperElement, {
        //         height: mobile ? "22px" : "40px",
        //       })
        //     : gsap.set(messageWrapperElement, {
        //         height: mobile ? " 9px" : "20px",
        //       });
        // },
      },
    });
    tl1
      .to(".integration_animation_title", {
        opacity: 1,
        y: 0,
        duration: 2,
      })
      .to(".keyboard_wrapper", {
        opacity: 1,
        duration: 2,
        delay: 0.7,
      })
      .to(".caps_lock_button", {
        opacity: 1,
        duration: 2,
        delay: 0.9,
      })
      .to(".chat_window", {
        opacity: 1,
        rotateX: 0,
        duration: 2,
        delay: 1.3,
      })
      .to(".caps_lock_button", {
        opacity: 0,
        duration: 1,
        delay: 0.9,
      })
      .to(splittedQuestionTxt?.chars, {
        position: "static",
        opacity: 1,
        duration: 0,
        stagger: 0.2,
        delay: 1.3,
      })
      .to(splittedQuestionTxt?.chars, {
        opacity: 0,
        duration: 0.1,
      })
      .set(".carrot", {
        className: "carrot_stop",
      })
      .to(".chating_wrapper", {
        opacity: 1,
        duration: 0.1,
      })
      .set(".chat_message", {
        className: "modified",
      })
      .to(splittedAnswerTxt.chars, {
        position: "static",
        opacity: 1,
        duration: 0,
        stagger: 0.2,
        delay: 1.3,
      })
      .set(".chat_message", {
        duration: 0.5,
        className: "chat_message",
      })
      .to(".stop_generating_wrapper", {
        opacity: 0,
        duration: 0,
      })
      .to(".caps_lock_button", {
        opacity: 1,
        duration: 1,
        delay: 0.9,
      })
      .to(".chat_window", {
        opacity: 0,
        rotateX: 1,
        duration: 1,
        delay: 1.3,
      });
  }, []);

  return (
    <div className="integration_animation_page_wrapper">
      <span className="integration_animation_title">Seamless Integration</span>
      <div className="keyboard_wrapper">
        <img
          src={
            "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Capschat-images/keyboard.svg"
          }
          alt="keyboard"
          className="keyboard"
        />
        <img
          src={
            "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Capschat-images/caps_lock_button.svg"
          }
          alt="caps_lock_button"
          className="caps_lock_button"
        />
        <div className="chat_window">
          <div className="chating_wrapper">
            <div className="question_wrapper">
              <span className="chating_person">You</span>
              <span className="question">
                How do conversations compose the narrative?
              </span>
            </div>
            <div className="answer_wrapper">
              <div className="chating_person_wrapper">
                <span className="chating_person gradiented">AI</span>
                <img
                  src={
                    "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Capschat-images/copy_icon.svg"
                  }
                  alt="copy_icon"
                  className="copy_icon"
                />
              </div>
              <span className="answer" ref={answerTxtRef}>
                <span className="answer_first_line">
                  Conversations craft the narrative's essence, blending emotions
                  and
                </span>
                <span className="answer_second_line">
                  perspectives into an enticing tale of connection.
                </span>
              </span>
            </div>

            <div className="stop_generating_wrapper">
              <div className="snippet" data-title="dot-flashing">
                <div className="stage">
                  <div className="dot-flashing"></div>
                </div>
              </div>
              <div>
                <Button
                  text="Stop Generating"
                  className="stop_generating_button"
                />
              </div>
            </div>
          </div>
          <div className="chat_message_wrapper">
            <div className="chat_message">
              <div ref={questionTxtRef} className="message_wrapper">
                How do conversations compose the narrative?
              </div>
            </div>
            <div className="chat_user_info_wrapper">
              <div className="user_name_wrapper">
                <div className="name_icon">E</div>
                <span className="user_name">Elon Musk</span>
              </div>
              <div className="options_wrapper">
                <span>Options</span>
                <img
                  src={
                    "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Capschat-images/arrow-down.png"
                  }
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
