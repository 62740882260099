import { useCallback, useEffect, useMemo } from "react";
import { gsap } from "gsap";
import "./style.css";

const animationSettings = [
  {
    id: "masterTextPath",
    from: { attr: { startOffset: "0%" } },
    to: { ease: "none", duration: 40, attr: { startOffset: "100%" } },
  },
  {
    id: "masterTextPathClone",
    from: { attr: { startOffset: "-100%" } },
    to: { ease: "none", duration: 40, attr: { startOffset: "0%" } },
  },
];
export const InfiniteSquareText = () => {
  const createTimeline = useCallback(() => {
    const tl = gsap.timeline({ repeat: -1 });

    animationSettings.forEach((setting) => {
      tl.fromTo(`#${setting.id}`, setting.from, setting.to, 0);
    });

    return tl;
  }, []);

  useEffect(() => {
    const tl = createTimeline();
    return () => tl.kill(); // Cleanup on unmount
  }, [createTimeline]);

  const textContent = useMemo(
    () =>
      "SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0SEAMLESS CHATING \u00A0 SEAMLESS CHATING \u00A0",
    []
  );

  return (
    <svg
      className="infinite_text_svg"
      viewBox="0 0 175 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "rotate(90deg)" }}
    >
      <path
        id="logo-letter-path"
        d="M88.2754 238.909V238.916C129.225 238.633 162.494 203.544 162.494 160.397V91.3934C162.494 48.0715 128.954 12.8726 87.7754 12.8726C46.5965 12.8726 13.0566 48.0715 13.0566 91.3934V160.388C13.0566 203.71 46.5965 238.909 87.7754 238.909H88.2754Z"
      />

      {animationSettings.map((setting, i) => (
        <text key={`mainText${i}`} id={`mainText${i}`} className="textStyle">
          <textPath
            id={setting.id}
            xlinkHref="#logo-letter-path"
            startOffset="0%"
            className="textStyle"
          >
            {textContent}
          </textPath>
        </text>
      ))}
    </svg>
  );
};
