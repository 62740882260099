import "./style.css";
import { companiesData } from "../../data/companiesData";

export const Footer = () => {
  return (
    <div className="footer_wrapper">
      <div className="footer_top_section">
        <div className="crafted_wrapper">
          <span>Crafted By </span>
          <a href="https://www.solvee.am/" target="_blank" rel="noreferrer">
            <img
              src={
                "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/solvee.svg"
              }
              className="solvee_logo"
              alt="solvee_logo"
            />
          </a>
          <span>team</span>
        </div>
        <div className="companies_wrapper">
          {companiesData.map((logo) => (
            <a href={logo.link} target="_blank" rel="noreferrer" key={logo.src + logo.alt}>
              <img src={logo.src} alt={logo.alt} className="company_logo" />
            </a>
          ))}
        </div>
      </div>
      <div className="copyright_section_wrapper">
        <span className="copyright_element">©2023 VRTL WORLD</span>
        <span className="copyright_element">ALL RIGHTS RESERVED</span>
        <span className="copyright_element">WE RESPECT YOUR PRIVACY</span>
      </div>
    </div>
  );
};
