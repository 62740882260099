import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const useSectionVisibility = (sectionRef) => {
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const sectionElement = sectionRef.current;

    const handleScroll = () => {
      const bounds = sectionElement.getBoundingClientRect();
      const visible = bounds.top < window.innerHeight && bounds.bottom >= 0;
      setIsSectionVisible(visible);
    };

    const scrollListener = () => {
      handleScroll();
    };

    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
      ScrollTrigger.getById(sectionElement.id) && ScrollTrigger.getById(sectionElement.id).kill();
    };
  }, [sectionRef]);

  return isSectionVisible;
};

export default useSectionVisibility;
