import "./style.css";

export const SectionMainInfo = ({ text, className }) => {
  return (
    <span
      className={`${className}  section_info_text`}
    >
      {text}
    </span>
  );
};
